import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Form from './Form'
import List from './List'

const ProposalCRUD = () => {
    return (
        <Switch>
            <Route path="/admin/proposal" exact>
                <List />
            </Route>
            <Route path="/admin/proposal/:proposalId">
                <Form />
            </Route>
        </Switch>
    )
}

export default ProposalCRUD