import './styles.css';
import { Link } from 'react-router-dom';
import { AxiosRequestConfig } from 'axios';
import { requestBackend } from 'util/requests';
import { ProposalType } from 'pages/FinalBuy';

type Props = {
  proposal: ProposalType;
  onDelete: Function;
};

const ProposalCrudCard = ({ proposal, onDelete }: Props) => {
  console.log(proposal.id)
  const handleDelete = (userId: number) => {
    if (!window.confirm('Tem certeza que deseja Excluir esse usuário?')) {
      return;
    }

    const config: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/proposal/${userId}`,
      withCredentials: true,
    };

    requestBackend(config).then(() => {
      onDelete();
    });
  };

  return (
    <div className="base-card user-crud-card">
      <div className="user-crud-card-content">
        <h6>{`Nome: ${proposal.nameUser}`}</h6>
        <h6>{`email: ${proposal.emailPerson}`}</h6>
        <h6>{`${proposal.telefone}`}</h6>
        <h6>{`data: ${proposal.data}`}</h6>

        {
          proposal.delivery ?
            <div>
              <h5>pedido: Entregar no local</h5>

              <h6>{`cep: ${proposal.cep}`}</h6>
              <h6>{`cidade: ${proposal.cidade}`}</h6>
              <h6>{`bairro: ${proposal.bairro}`}</h6>
              <h6>{`rua: ${proposal.rua}`}</h6>
              <h6>{`numero da rua: ${proposal.numero}`}</h6>
            </div>
            :
            <div>
              <h4>Pegar na loja</h4>
            </div>
        }

      </div>
      <div className="user-crud-card-buttons-container">
        <Link to={`/admin/proposal/${proposal.id}`}>
          <button className="btn btn-outline-secondary user-crud-card-button">
            EDITAR
          </button>
        </Link>
        <button
          onClick={() => handleDelete(proposal.id)}
          className="btn btn-outline-danger user-crud-card-button"
        >
          EXCLUIR
        </button>
      </div>
    </div>
  );
};

export default ProposalCrudCard;
