import { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { requestBackend } from 'util/requests';
import { toast } from 'react-toastify';
import './styles.css';
import { ProposalType } from 'pages/FinalBuy';

type UrlParams = {
  proposalId: string;
};

const Form = () => {
  const { proposalId } = useParams<UrlParams>();
  const isEditing = proposalId !== 'create';
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ProposalType>();

  useEffect(() => {
    if (isEditing) {
      requestBackend({ url: `/proposal/${proposalId}`, withCredentials: true }).then(
        (response) => {
          const proposal = response.data as ProposalType;

          setValue('id', proposal.id);
          setValue('nameUser', proposal.nameUser);
          setValue('product', proposal.product);
          setValue('emailPerson', proposal.emailPerson);
          setValue('cep', proposal.cep);
          setValue('cidade', proposal.cidade);
          setValue('bairro', proposal.bairro);
          setValue('rua', proposal.rua);
          setValue('numero', proposal.numero);
          setValue('telefone', proposal.telefone);
          setValue('delivery', proposal.delivery);
          setValue('data', proposal.data);
        }
      );
    }
  }, [isEditing, proposalId, setValue]);

  const onSubmit = (formData: ProposalType) => {
    const data = {
      ...formData,
    };

    const config: AxiosRequestConfig = {
      method: isEditing ? 'PUT' : 'POST',
      url: isEditing ? `/proposal/${proposalId}` : '/proposals',
      data,
      withCredentials: true,
    };

    requestBackend(config)
      .then(() => {
        toast.info('Proposal successfully registered');
        history.push('/admin/proposal');
      })
      .catch(() => {
        toast.error('Error registering proposal');
      });
  };

  const handleCancel = () => {
    history.push('/admin/proposal');
  };

  return (
    <div className="proposal-crud-container">
      <div className="base-card proposal-crud-form-card">
        <h1 className="proposal-crud-form-title">
          {isEditing ? 'EDITAR PROPOSTA' : 'REGISTRAR PROPOSTA'}
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row product-crud-inputs-container">
            <div className="col-lg-6 product-crud-inputs-left-container">
              <div className="margin-botton-30">
                <input {...register('nameUser', { required: 'Campo Obrigatório' })} type="text" className={`form-control base-input ${errors.nameUser ? 'is-invalid' : ''}`} placeholder="Nome do cliente" name="nameUser" />
                <div className="invalid-feedback d-block">{errors.nameUser?.message}</div>
                <input {...register('emailPerson', { required: 'Campo Obrigatório' })} type="email" className={`form-control base-input ${errors.emailPerson ? 'is-invalid' : ''}`} placeholder="Email" name="user" />
                <div className="invalid-feedback d-block">{errors.emailPerson?.message}</div>
                <input {...register('cep', { required: 'Campo Obrigatório' })} type="text" className={`form-control base-input ${errors.cep ? 'is-invalid' : ''}`} placeholder="CEP" name="cep" />
                <div className="invalid-feedback d-block">{errors.cep?.message}</div>
                <input {...register('cidade', { required: 'Campo Obrigatório' })} type="text" className={`form-control base-input ${errors.cidade ? 'is-invalid' : ''}`} placeholder="Cidade" name="cidade" />
                <div className="invalid-feedback d-block">{errors.cidade?.message}</div>
                <input {...register('bairro', { required: 'Campo Obrigatório' })} type="text" className={`form-control base-input ${errors.bairro ? 'is-invalid' : ''}`} placeholder="Bairro" name="bairro" />
                <div className="invalid-feedback d-block">{errors.bairro?.message}</div>
                <input {...register('rua', { required: 'Campo Obrigatório' })} type="text" className={`form-control base-input ${errors.rua ? 'is-invalid' : ''}`} placeholder="Rua" name="rua" />
                <div className="invalid-feedback d-block">{errors.rua?.message}</div>
                <input {...register('numero', { required: 'Campo Obrigatório' })} type="text" className={`form-control base-input ${errors.numero ? 'is-invalid' : ''}`} placeholder="Numero" name="numero" />
                <div className="invalid-feedback d-block">{errors.numero?.message}</div>
                <input {...register('telefone', { required: 'Campo Obrigatório' })} type="text" className={`form-control base-input ${errors.telefone ? 'is-invalid' : ''}`} placeholder="Telefone" name="telefone" />
                <div className="invalid-feedback d-block">{errors.telefone?.message}</div>
                <div className='form-check'>
                  <label className='form-check-label' form='form-check-input'>Mudar para entrega ?</label>
                  <input className='form-check-input' type="checkbox" {...register('delivery')} name="delivery" />
                </div>
                <input {...register('data', { required: 'Campo Obrigatório' })} type="text" className={`form-control base-input ${errors.data ? 'is-invalid' : ''}`} placeholder="Data" name="data" />
                <div className="invalid-feedback d-block">{errors.data?.message}</div>
              </div>
            </div>
          </div>
          <div className="proposal-crud-buttons-container">
            <button className="btn btn-primary proposal-crud-button text-white">SALVAR</button>
            <button className="btn btn-outline-danger proposal-crud-button" onClick={handleCancel}>CANCELAR</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
