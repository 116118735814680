import './styles.css';
import 'bootstrap/js/src/collapse.js';


const Footer = () => {
    return (
        <footer className='footer'>
            <div>
                feito por 
                <strong onClick={() => window.open('https://ziel-tech.vercel.app')}>
                    _ziel tech
                </strong>
            </div>
        </footer>
    );
};

export default Footer;
