import axios from 'axios';
import ProductPrice from 'components/ProductPrice';
import history from 'util/history';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Product } from 'types/products';
import { BASE_URL } from 'util/requests';
import './style.css';
import { AuthContext } from 'AuthContext';
import { isAuthenticated } from 'util/auth';
import { getTokenData } from 'util/token';
import ButtonIcon from 'components/ButtonIcon';

type UrlParams = {
    productId: string;
};

const Compra: React.FC = () => {
    const { productId } = useParams<UrlParams>();
    const { authContextData, setAuthContextData } = useContext(AuthContext);
    
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState<Product>();
    const [delivery, setDelivery] = useState(false);
    const [contactDetails, setContactDetails] = useState({
        cep: '',
        cidade: '',
        bairro: '',
        rua: '',
        numero: '',
        telefone: ''
    });

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL}/products/${productId}`)
            .then(response => setProduct(response.data))
            .finally(() => setIsLoading(false));

        if (isAuthenticated()) {
            setAuthContextData({
                authenticated: true,
                tokenData: getTokenData(),
            });
        } else {
            setAuthContextData({ authenticated: false });
        }
    }, [productId, setAuthContextData]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
    };

    const handleDeliveryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDelivery(e.target.value === "delivery");
    };

    const sendProposal = () => {
        const endPoint = '/proposal';
        axios.post(`${BASE_URL}${endPoint}`, {
            emailPerson: authContextData.tokenData?.user_name,
            product: product,
            delivery: delivery,
            ...contactDetails
        })
        .then(response => {
            history.push('/final/buy', { proposal: { 
                user: authContextData.tokenData?.user_name, 
                product: product, 
                delivery: delivery, 
                ...contactDetails 
            }})
        })
        .catch(error => {console.error(error); alert("Erro ao enviar proposta")});
    };

    if (isLoading) {
        return <div>Carregando...</div>;
    }

    return (
        <div>
            <div className="product-details-container">
                <div className="base-card product-details-card">
                    <div className="name-price-container prod-data">
                        <img src={product?.imgUrl} alt={product?.name} height={150} />
                        <h1>{product?.name}</h1>
                        {product && <ProductPrice price={product?.price} />}
                    </div>
                </div>
            </div>
            <div className="product-details-container">
                <div className="base-card product-details-card">
                    {authContextData.authenticated ? (
                        <div className='login-card-container'>
                            <h2>Opções de entrega:</h2>
                            <div className='select-container'>
                                <div className='input-deli'>
                                    <input
                                        type="radio"
                                        id="pickup"
                                        name="delivery"
                                        value="pickup"
                                        onChange={handleDeliveryChange} />
                                    <label htmlFor="pickup">
                                        Prefiro pegar no local
                                    </label><br />
                                </div>

                                <div className='input-deli'>
                                    <input type="radio"
                                        id="delivery"
                                        name="delivery"
                                        value="delivery"
                                        onChange={handleDeliveryChange} />
                                    <label htmlFor="delivery">
                                        Opto pela entrega
                                    </label>
                                </div>

                            </div>
                            {delivery ? (
                                <div className='input-email'>
                                    <input className='form-control base-input'
                                        placeholder='Coloque seu CEP'
                                        type="text"
                                        id="deliveryAddress"
                                        name="cep"
                                        onChange={handleInputChange}
                                    /><br />
                                    <input className='form-control base-input'
                                        placeholder='Sua cidade'
                                        type="text"
                                        id="city"
                                        name="cidade"
                                        onChange={handleInputChange}
                                    /><br />
                                    <input className='form-control base-input'
                                        placeholder='Seu bairro'
                                        type="text"
                                        id="district"
                                        name="bairro"
                                        onChange={handleInputChange}
                                    /><br />
                                    <input className='form-control base-input'
                                        placeholder='Sua rua'
                                        type="text"
                                        id="street"
                                        name="rua"
                                        onChange={handleInputChange}
                                    /><br />
                                    <input className='form-control base-input'
                                        placeholder='Numero da residência'
                                        type="text"
                                        id="residenceNumber"
                                        name="numero"
                                        onChange={handleInputChange}
                                    /><br />
                                    <div>
                                        <label htmlFor="phoneNumber">Número de telefone:</label>
                                        <input className='form-control base-input'
                                            type="text" id="phoneNumber"
                                            name="telefone" onChange={handleInputChange} />
                                    </div>
                                    O valor da entrega será dado pelo whatsapp
                                    <div onClick={sendProposal}>
                                        <ButtonIcon text='Enviar' />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <label htmlFor="phoneNumber">Número de telefone:</label>
                                    <input className='form-control base-input'
                                        type="text" id="phoneNumber" name="telefone" onChange={handleInputChange} />
                                    <div onClick={sendProposal}>
                                        <ButtonIcon text='Enviar' />
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p>Por favor, faça login para continuar a compra</p>
                            <Link to={'/admin/auth/login'}>
                                <ButtonIcon text='Entrar' />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Compra;
