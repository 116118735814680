import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './styles.css';
import { Product } from 'types/products';
import ButtonIcon from 'components/ButtonIcon';

export type ProposalType = {
    id: number;
    nameUser: string;
    emailPerson: string;
    product: Product;
    cep: string;
    cidade: string;
    bairro: string;
    rua: string;
    numero: string;
    telefone: string;
    delivery: boolean;
    data: string;
}

const FinalBuy: React.FC = () => {
    const locationState = useLocation<{ proposal: ProposalType }>().state;

    // Verifique se o estado de localização e a proposta existem
    const proposal = locationState?.proposal;

    if (!proposal) {
        return <div>Carregando...</div>;  // Retorna um carregamento ou uma mensagem de erro, etc.
    }
    console.log(proposal)
    return (
        <div className="proposal-details-container">
            <div className="base-card-proposal">
                <h2>Entraremos em contato em breve para fechar o valor final...</h2>
                <h2 className=''>Resumo da proposta:  </h2>
                <div className="product-details-card proposal">
                    <div>
                        Email: <strong>{proposal.emailPerson}</strong>
                    </div>
                    <div>
                        produto: <strong>{proposal.product.name}</strong>
                    </div>
                    <div>
                        Preço: <strong>R${proposal.product.price}</strong>
                    </div>
                    <div>
                        Telefone: <strong>{proposal.telefone}</strong>
                    </div>
                    Dados de entrega:
                    <div>
                        {proposal.delivery ?
                            <div>
                                <div>
                                    cep: <strong>{proposal.cep}</strong>
                                </div>
                                <div>
                                    Cidade: <strong> {proposal.cidade}</strong>
                                </div>
                                <div>
                                    Bairro: <strong>{proposal.bairro}</strong>
                                </div>
                            </div>
                            :
                            <strong>- Pegar na loja</strong>}
                    </div>
                    <Link to={"/products"}>
                        <ButtonIcon text='Continuar comprando' />
                    </Link>

                </div>
            </div>
        </div>
    )
}

export default FinalBuy;
